































































































import { downloadFile } from "@/helpers/file-reader";
import MNotificationVue from "@/mixins/MNotification.vue";
import { DATE_FORMAT_REPORT, DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_TIME } from "@/models/constant/date.constant";
import { ReportJobCosting } from "@/models/interface-v2/report.interface";
import { reportService } from "@service/report.service";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "JobCostingReport",
  components: {
    CSelectJobCostingNumber: () => import(/*webpackPrefetch: true */"@/components/shared/select-job-costing-number/CSelectJobCostingNumber.vue"),
    CSelectCustomer: () => import(/*webpackPrefetch: true */"@/components/shared/select-customer/CSelectCustomer.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        whPickDateFrom: null as Moment | null,
        whPickDateTo: null as Moment | null,
        branchId: undefined as string | undefined,
        branchName: "",
        customerId: undefined as string | undefined,
        periodFrom: null as Moment | null,
        periodTo: null as Moment | null,
        jobCostingId: undefined as string | undefined,
        jobCostingNumber: "",
      },
      rules: {
        branchId: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        periodFrom: [{ required: false, message: () => this.$t("lbl_validation_required_error") }],
        periodTo: [{ required: false, message: () => this.$t("lbl_validation_required_error") }],
        jobCostingId: [{ required: false, message: () => this.$t("lbl_validation_required_error") }],
        customerId: [{ required: false, message: () => this.$t("lbl_validation_required_error") }],
      },
      loading: false,
    };
  },
  methods: {
    onClickBranch({ meta }): void {
      this.form.branchName = meta.name;
    },
    onSelectJobCosting({ meta }): void {
      this.form.jobCostingNumber = meta.jobCostingNumber;
    },
    validateForm(): void {
      const form = this.$refs.form as FormModel;
      form.validate((valid: boolean) => {
        if (valid) {
          this.handleDownload();
        } else {
          this.showNotifValidationError();
        }
      });
    },
    async handleDownload(): Promise<void> {
      try {
        this.loading = true;
        const payload: ReportJobCosting = {
          branchId: this.form.branchId || "",
          branchName: this.form.branchName,
          customerId: this.form.customerId || "",
          periodFrom: this.form.periodFrom?.format(DATE_FORMAT_REPORT) || "",
          periodTo: this.form.periodTo?.format(DATE_FORMAT_REPORT) || "",
          jobCostingId: this.form.jobCostingId || "",
          jobCostingNumber: this.form.jobCostingNumber,
          pickingDateFrom: this.form.whPickDateFrom?.format(DATE_FORMAT_REPORT) || "",
          pickingDateTo: this.form.whPickDateTo?.format(DATE_FORMAT_REPORT) || "",
        };
        const file = await reportService.jobCosting(payload);
        const now = moment().format(DEFAULT_DATE_FORMAT_TIME);
        downloadFile(new Blob([file]), `report_job_costing_(${now}).xlsx`);
      } catch (error) {
        this.showErrorMessage("notif_download_error");
      } finally {
        this.loading = false;
      }
    },
    handleClear(): void {
      const form = this.$refs.form as FormModel;

      form.resetFields();
      this.form.branchName = "";
      this.form.jobCostingNumber = "";
    },
    handleBack(): void {
      this.$router.back();
    }
  }
});
