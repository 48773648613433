var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_report_job_costing") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": { span: 5 },
            "wrapper-col": { span: 6 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateForm.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { prop: "branchId", label: _vm.$t("lbl_branch") } },
            [
              _c("CSelectBranch", {
                on: {
                  "on-click": _vm.onClickBranch,
                  "on-init": _vm.onClickBranch
                },
                model: {
                  value: _vm.form.branchId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "branchId", $$v)
                  },
                  expression: "form.branchId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_job_costing_date_from"),
                prop: "periodFrom"
              }
            },
            [
              _c("a-date-picker", {
                attrs: {
                  placeholder: _vm.$t("lbl_choose"),
                  format: _vm.DEFAULT_DATE_FORMAT
                },
                model: {
                  value: _vm.form.periodFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "periodFrom", $$v)
                  },
                  expression: "form.periodFrom"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_job_costing_date_to"),
                prop: "periodTo"
              }
            },
            [
              _c("a-date-picker", {
                attrs: {
                  placeholder: _vm.$t("lbl_choose"),
                  format: _vm.DEFAULT_DATE_FORMAT
                },
                model: {
                  value: _vm.form.periodTo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "periodTo", $$v)
                  },
                  expression: "form.periodTo"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("x_date_from", {
                  x: _vm.$t("lbl_warehouse_picked")
                }),
                prop: "whPickDateFrom"
              }
            },
            [
              _c("a-date-picker", {
                attrs: {
                  placeholder: _vm.$t("lbl_choose"),
                  format: _vm.DEFAULT_DATE_FORMAT
                },
                model: {
                  value: _vm.form.whPickDateFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "whPickDateFrom", $$v)
                  },
                  expression: "form.whPickDateFrom"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("x_date_to", {
                  x: _vm.$t("lbl_warehouse_picked")
                }),
                prop: "whPickDateTo"
              }
            },
            [
              _c("a-date-picker", {
                attrs: {
                  placeholder: _vm.$t("lbl_choose"),
                  format: _vm.DEFAULT_DATE_FORMAT
                },
                model: {
                  value: _vm.form.whPickDateTo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "whPickDateTo", $$v)
                  },
                  expression: "form.whPickDateTo"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_job_costing_number"),
                prop: "jobCostingId"
              }
            },
            [
              _c("CSelectJobCostingNumber", {
                on: { "on-select": _vm.onSelectJobCosting },
                model: {
                  value: _vm.form.jobCostingId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "jobCostingId", $$v)
                  },
                  expression: "form.jobCostingId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_customer"), prop: "customerId" } },
            [
              _c("CSelectCustomer", {
                model: {
                  value: _vm.form.customerId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "customerId", $$v)
                  },
                  expression: "form.customerId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { "wrapper-col": { span: 8, offset: 5 } } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleClear } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        icon: "download",
                        type: "primary",
                        loading: _vm.loading,
                        "html-type": "submit"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }